'use client'
import React from 'react'

const DeviceContext = React.createContext<'mobile' | 'tablet' | 'desktop'>(
  'mobile',
)

export const DeviceProvider = ({
  children,
  device,
}: {
  children: React.ReactNode
  device: 'mobile' | 'tablet' | 'desktop'
}) => {
  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  )
}

export const useDevice = () => {
  const context = React.useContext(DeviceContext)
  if (context === undefined) {
    throw new Error('useDevice must be used within a DeviceProvider')
  }
  return context
}
